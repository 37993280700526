<template>
  <div>
    <b-row class="mt-3">
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="m-0">SHERQ Mail List</h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="12">

              <b-table striped hover 
                       :items="tableData.dataSource" 
                       :fields="tableData.tableColumns"
                       :busy="tableData.isLoading" 
                       :per-page="tableData.perPage"
                        :current-page="tableData.currentPage"                       
                       id="mailList"
                       @row-clicked="openSherq"
                       sort-icon-left>

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>

                <!-- <template #cell(lastLoginDate)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{row.item.lastLoginDate | dateTimeFilter}}</span>
                  </b-row>
                </template> -->

                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="openSherq(row.item)" size="sm" class="btn-icon">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
              <!-- <b-row align-h="center" >
                <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="sherqMailList"
                ></b-pagination>
              </b-row> -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    
    <b-modal id="sherq-modal" size="xl" hide-footer  @close="closeSherqModal()">
      <!-- <template #modal-header="{}"> -->
      <b-row><b-col>
        <h5>SHERQ Mail List</h5>
      </b-col></b-row>
      <!-- </template> -->
      <b-row>
        <b-col>
          <label>Business Unit</label>
          <b-form-input disabled v-model="listItem.businessUnit"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>User</label>
          <b-input-group>
            <b-form-input v-model="listItem.userName" :disabled="true"></b-form-input>
            <b-input-group-append>
              <b-button size="sm" text="Button" variant="secondary" @click="openUserSearchModal()">Select</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <label>Alternative Emails</label>
          <b-form-input v-model="listItem.altEmails"></b-form-input>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col>
          <label>Selected CheckLists</label>
        </b-col>
        <b-col>
          <b-button size="sm" text="Button" variant="secondary" @click="openChecklistModal()">Add Checklist</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table striped hover 
                       :items="selectedItem.checkLists" 
                       :fields="KeyValueColumns"
                       :busy="tableData.isLoading" 
                       id="checklists"
                       sort-icon-left>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
              </div>
            </template>

            <template #cell(actions)="row">
              <b-row align-v="center" align-h="end">
                <b-button @click="removeChecklist(row.item)" size="sm" class="btn-icon">
                  Delete<!-- <b-icon-clipboard></b-icon-clipboard> -->
                </b-button>
              </b-row>
            </template>
          </b-table>

        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="red"  @click="closeSherqModal()" >Cancel</b-button>
        </b-col>
        <b-col>
          <b-button variant="primary" @click="saveChecklist()" >Save</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="search-client-modal" size="xl"  hide-footer no-header @close="closeUserSearchModal()">
      <b-card>
        <b-row>
          <b-col>
            <h4>Search Users</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <label>Name</label>
            <b-form-input v-model="user.name"></b-form-input>
          </b-col>
          <b-col cols="4">
            <label>Surname</label>
            <b-form-input v-model="user.surname"></b-form-input>
          </b-col>
          <b-col cols="4">
            <label>Email</label>
            <b-form-input v-model="user.email"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button variant="red" class="ml-2" squared @click="clearSearch" >Cancel</b-button>
            <b-button variant="primary" squared @click="searchUsers" class="ml-2">Search</b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col>
          <b-table striped hover 
                       :items="usertableData.dataSource" 
                       :fields="usertableData.tableColumns"
                       :busy="usertableData.isLoading" 
                       @row-clicked="selectUser"
                       :per-page="usertableData.resultsPerPage"
                       id="userSearch"
                       :current-page="usertableData.currentPage" sort-icon-left>

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="selectUser(row.item)" size="sm" class="btn-icon">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
              <b-row align-h="center" >
                <b-pagination
                    v-model="usertableData.currentPage"
                    :total-rows="usertableDatarows"
                    :per-page="usertableData.resultsPerPage"
                    aria-controls="userSearch"
                ></b-pagination>
              </b-row>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="checklist-modal" size="xl"  hide-footer no-header @close="closeChecklistModal()">
      <b-row>
        <b-col>
          <h4>Search Checklists</h4>
        </b-col>
      </b-row>
      <b-row>
          <b-col>
            <b-table striped hover
                      :items="checklisttableData.dataSource"
                      :fields="checklisttableData.tableColumns"
                      :busy="checklisttableData.isLoading"
                      @row-clicked="addChecklist"
                      :per-page="checklisttableData.resultsPerPage"
                      id="stockTable"
                      :current-page="checklisttableData.currentPage" sort-icon-left>

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>

                <template #cell(completedDate)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{row.item.completedDate | dateTimeFilter}}</span>
                  </b-row>
                </template>

                <template #cell(actions)="row">
                  <b-row @click="addChecklist(row.item)" align-v="center" align-h="end">
                    <b-button size="sm" class="btn-icon">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                  
                </template>
              </b-table>
              <b-row align-h="center" >
                <b-pagination
                    v-model="checklisttableData.currentPage"
                    :total-rows="checklisttableDatarows"
                    :per-page="checklisttableData.resultsPerPage"
                    aria-controls="stockTable"
                ></b-pagination>
              </b-row>
          </b-col>
      </b-row>
    </b-modal>  
  </div>
</template>

<script>
// import Vue from 'vue'
import {mapActions, mapMutations} from "vuex";
//import JsonCSV from "vue-json-csv";

// Vue.component('downloadCsv', JsonCSV)

export default {
  name: "mailList",
  data: () => ({
    showSherq:false,
    selectedItem: {
      buId: 0,
      businessUnit: null,
      userId: null,
      userName: null,
      altEmails: null,
      checkLists: null,
      checkListCount:null,
    },
    user: {
      userId: 0,
      name: null,
      surname: null,
      contactNumber: null,
      email: null,
      createdDate: null,
      lastLoginDate: null,
      role: null,
      roleID: 0,
      active: null,
    },

    listItem: {
      buId: 0,
      businessUnit: null,
      userId: null,
      userName: null,
      altEmails: null,
      checkLists: null,
      checkListCount:null,
    },

    tableData: {
      resultsPerPage: 15,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Business Unit',
          key: 'businessUnit',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Name',
          key: 'userName',
          sortable: true,
          tdClass: '',
        },
        // {
        //   label: 'Alternative Emails',
        //   key: 'altEmails',
        //   sortable: true,
        //   tdClass: '',
        // },
        {
          label: 'Checklists',
          key: 'checkListCount',
          sortable:false,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }

      ]
    },
    checklisttableData: {
      resultsPerPage: 15,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Checklist Description',
          key: 'description',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Type',
          key: 'checkListType',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },

    usertableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: false,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Surname',
          key: 'surname',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: '',
        },
        // {
        //   label: 'Contact Number',
        //   key: 'contactNumber',
        //   sortable: true,
        //   tdClass: '',
        // },
        // {
        //   label: 'Role',
        //   key: 'role',
        //   sortable: true,
        //   tdClass: '',
        // },
        // {
        //   label: 'Last Logged In',
        //   key: 'lastLoginDate',
        //   sortable: true,
        //   tdClass: '',
        // },
        // {
        //   label: '',
        //   key: 'actions',
        //   sortable: false,
        //   tdClass: ''
        // }
      ]
    },
    
    KeyValueColumns:[
      {
        label: 'ID',
        key: 'key',
        sortable: true,
        tdClass: '',
      },
      {
        label: 'CheckList',
        key: 'value',
        sortable: true,
        tdClass: '',
      },
      {
        label: '',
        key: 'actions',
        sortable: false,
        tdClass: '',
      },

  ],

    jsonData: [],
  }),
  created() {
    this.LoadCheckListSherqMailList()
    //this.userRole()
    this.setBreadcrumb([
      {
        text: 'SHERQ'
      },
    ])
  },
  methods: {
    ...mapActions(['GetCheckListSherqMailList', "DeleteSherqMailList", 'AddSherqMailList','searchUsersRequest','searchChecklists']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addWarningMessage', 'addSuccessMessage']),

    LoadCheckListSherqMailList() {
      this.tableData.isLoading = true
      
      this.GetCheckListSherqMailList()
      .then((request) => {
        this.tableData.dataSource = request.data
        
        // this.jsonData = this.tableData.dataSource.map(v => ({
        //   name: v.name,
        //   surname: v.surname,
        //   contactNumber: v.contactNumber,
        //   email: v.email,
        //   role: v.role,
        //   lastLoginDate: v.lastLoginDate === null ? '' : new Date(v.lastLoginDate).toLocaleDateString('en-ZA')
        // }))
        
        this.tableData.isLoading = false
        this.state  = 'initialize'
      })
      .catch( () => {
        this.tableData.isLoading = false
      })
    },
    
    openSherq(sherq){
      console.log('sherq',sherq);
      this.listItem = sherq;
      this.selectedItem = Object.assign({}, sherq);

      //this.showSherq = true;
      this.$root.$emit('bv::show::modal', 'sherq-modal', '#btnShow');
      },

    closeSherqModal(){
      //console.log("Closing Modal")
      //this.clearValues();
      //this.showSherq = false;
      // this.$bvModal.hide('add-time-modal')
      this.$root.$emit('bv::hide::modal', 'sherq-modal', '#btnShow')
    },

    openChecklistModal(){
      this.$root.$emit('bv::show::modal', 'checklist-modal', '#btnShow');
      this.doSearchChecklist();
    },
    closeChecklistModal(){
      this.$root.$emit('bv::hide::modal', 'checklist-modal', '#btnShow')
    },

    addChecklist(row){
      console.log('row',row)

      var kv = {key:row.checkListId, value:row.description}
      console.log('kv',kv)

      if (this.selectedItem.checkLists ==null){
        this.selectedItem.checkLists = []
      }
      console.log('this.selectedItem.',this.selectedItem)

      this.selectedItem.checkLists.push(kv)
      console.log('this.selectedItem',this.selectedItem)

      this.closeChecklistModal()
    },

    deleteMail(sherq) {
      this.$store.commit('setsherq', sherq)
//      this.$router.push({path: '/admin/userView'})
    },
    
    selectUser(user){
      console.log('user',user)
      this.selectedItem.userId = user.userId
      this.selectedItem.userName = user.name + ' ' +  user.surname
      this.listItem.userName = user.name + ' ' +  user.surname
      this.closeUserSearchModal()
    },

    openUserSearchModal() {
      //this.usertableData.isLoading = true;

      this.$root.$emit('bv::show::modal', 'search-client-modal', '#btnShow');
      console.log('this.usertableData.dataSource',this.usertableData.dataSource)
      
      if (this.usertableData.dataSource.length==0)
      {
        console.log("searchuserss")
        this.searchUsers();
      }
      
      //this.usertableData.isLoading= false;
      // this.contactValues = {
      //   name: null,
      //   surname: null,
      //   email: null,
      //   cellNumber: null,
      //   company: this.request.company,
      //   location: { locationId: null, locationName: null },
      //   newLocation: { locationName: null, address: null, city: null, country: null, postalCode: null },
      //   landlineNumber: null,
      // };
      //this.doSearchByCompany();
    },
    closeUserSearchModal() {
      this.$root.$emit('bv::hide::modal', 'search-client-modal', '#btnShow');
    },

    clearSearch() {
      this.user = {
        name: null,
        surname: null,
        email: null,
      }
      this.searchUsers()
    },

    removeChecklist(checklistitem){
      console.log("cheklistitem", checklistitem)
      this.selectedItem.checkLists = this.selectedItem.checkLists.filter(function(item){
        return item!= checklistitem
      })
      this.selectedItem.checkListCount--
      console.log('delete', this.selectedItem.checkLists)

    },

    saveChecklist(){
      this.$store.commit('setMailListRequest', this.selectedItem);
      
      this.AddSherqMailList().then((res) => {
        //this.possibleLocations = res.data;
        console.log('res',res)
        this.listItem = Object.assign({}, this.selectedItem);
        console.log('this.listItem',this.listItem)
        this.LoadCheckListSherqMailList()
        this.closeSherqModal()
      })
      .catch( () => {
        this.tableData.isLoading = false
      })

    },

    searchUsers() {
      this.usertableData.isLoading = true
      
      let request = []
      
      if (this.user.name != null) {
        request.push({'key': 'name', 'value': this.user.name})
      }
      if (this.user.surname != null) {
        request.push({'key': 'surname', 'value': this.user.surname})
      }
      if (this.user.email != null) {
        request.push({'key': 'email', 'value': this.user.email})
      }

      this.$store.commit('setUserSearchRequest', request)
      
      console.log('searchUsersRequest',this.searchUsersRequest)
      this.searchUsersRequest()
      .then((request) => {
        console.log('request.data',request.data)
        this.usertableData.dataSource = request.data
        
        // this.jsonData = this.tableData.dataSource.map(v => ({
        //   name: v.name,
        //   surname: v.surname,
        //   contactNumber: v.contactNumber,
        //   email: v.email,
        //   role: v.role,
        //   lastLoginDate: v.lastLoginDate === null ? '' : new Date(v.lastLoginDate).toLocaleDateString('en-ZA')
        // })
        //)
        
        this.usertableData.isLoading = false
        //this.state  = 'initialize'
      })
      .catch( (err) => {
        console.log('err',err)
        this.usertableData.isLoading = false
      })
    },
    
    doSearchChecklist(){
      //this.clearMessage()
      this.checklisttableData.isLoading = true
      
      let request = []
      // if(this.search.typeId !== null){
      //     request.push({"key":"fkcheckListTypeid","value": this.search.typeId})
      // }
      // if(this.search.description !== null){
      //     request.push({"key":"description","value": this.search.description})
      // }

      this.$store.commit('setChecklistSearchRequest', request)
      this.searchChecklists()
      .then((res) => {
          this.checklisttableData.dataSource = res.data
          this.checklisttableData.isLoading = false
      })
      .catch(() => {
          this.checklisttableData.isLoading = false
      })
    },

  },
  computed: {
      rows() {
          return this.tableData.dataSource.length
      },
      usertableDatarows()
      {

        return this.usertableData.dataSource.length
      },
      checklisttableDatarows()
      {

        return this.checklisttableData.dataSource.length
      }

  },
}
</script>

<style scoped>

</style>